<!--
居家监护
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          数据统计分析
        </div>
      </div>
    </div>

    <!--维护记录-->
    <div class="shipMain">
      <!--数据统计分析-->
      <div class="shipNav">
        <img
          src="@/assets/img/homeGuardianship/dataActive.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">设备数据&服务次数</span>
      </div>
      <div class="dataBox">
        <div class="dataInfo" ref="device"></div>
        <div class="dataInfo" ref="service"></div>
      </div>
      <!-- 服务增长趋势 -->
      <div class="shipNav navMargin">
        <img
          src="@/assets/img/homeGuardianship/dataActive.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">服务增长趋势</span>
      </div>
      <div class="serviceLine" ref="serviceLine"></div>
    </div>
  </div>
</template>
<script>
import {
  GetWorkOrderSumWithTypeList,
  GetOrderSumWithTypeList,
  GetWorkOrderSumMonthList,
  GetOrderSumMonthList,
} from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      deviceColum: null,
      devicreOption: {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        color: ["#FBD508", "#FF7B05", "#FEA503", "#FD1604"], //环形图两部分的颜色
        graphic: [
          {
            //环形图中间添加文字
            type: "text", //通过不同top值可以设置上下显示
            left: "center",
            top: "40%",
            style: {
              text: "0",
              textAlign: "center",
              fill: "#000", //文字的颜色
              width: 30,
              height: 30,
              fontSize: 18,
              color: "#4d4f5c",
              fontFamily: "Microsoft YaHei",
            },
          },
          {
            type: "text",
            left: "center",
            top: "55%",
            style: {
              text: "工单数",
              textAlign: "center",
              fill: "#000",
              width: 30,
              height: 30,
              fontSize: 18,
            },
          },
        ],

        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            labelLine: {
              length: 50,
            },
            center: ["50%", "50%"],
            label: {
              formatter: "{a|{b}}{abg|}\n  {b|{c}} ",
              backgroundColor: "#F8F8F8",

              borderRadius: 4,
              rich: {
                a: {
                  padding: [10, 10],
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                  width: 80,
                },

                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                  align: "center",
                },
              },
            },
            data: [],
          },
        ],
      },
      serviceColum: null,
      serviceOption: {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        color: ["#0061F8", "#4A93F5", "#7B1FF4", "#03D1FF"], //环形图两部分的颜色
        graphic: [
          {
            //环形图中间添加文字
            type: "text", //通过不同top值可以设置上下显示
            left: "center",
            top: "40%",
            style: {
              text: "0",
              textAlign: "center",
              fill: "#000", //文字的颜色
              width: 30,
              height: 30,
              fontSize: 18,
              color: "#4d4f5c",
              fontFamily: "Microsoft YaHei",
            },
          },
          {
            type: "text",
            left: "center",
            top: "55%",
            style: {
              text: "订单",
              textAlign: "center",
              fill: "#000",
              width: 30,
              height: 30,
              fontSize: 18,
            },
          },
        ],

        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            labelLine: {
              length: 50,
            },
            center: ["50%", "50%"],
            label: {
              formatter: "{a|{b}}{abg|}\n  {b|{c}} ",
              backgroundColor: "#F8F8F8",

              borderRadius: 4,
              rich: {
                a: {
                  padding: [10, 10],
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                  width: 80,
                },

                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                  align: "center",
                },
              },
            },
            data: [],
          },
        ],
      },

      serviceLine: null,
      serviceLineOption: {
        tooltip: {
          trigger: "axis",
        },

        legend: {
          data: ["工单数", "订单数", "订单金额"],
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },

        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "工单数",
            type: "line",
            stack: "Total",
            data: [],
          },
          {
            name: "订单数",
            type: "line",
            stack: "Total",
            data: [],
          },
          {
            name: "订单金额",
            type: "line",
            stack: "Total",
            data: [],
          },
        ],
      },
      monthNum: 12,
    };
  },
  created() {},

  mounted: function () {
    //this.initChart();
    this.initDeviceChart();
    this.initServiceChart();
    this.getLinde();
  },
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    initChart() {
      this.deviceColum = this.$echarts.init(this.$refs.device);
      this.deviceColum.setOption(this.devicreOption);

      this.serviceColum = this.$echarts.init(this.$refs.service);
      this.serviceColum.setOption(this.serviceOption);

      this.serviceLine = this.$echarts.init(this.$refs.serviceLine);
      this.serviceLine.setOption(this.serviceLineOption);
    },
    //获取用户工单统计
    initDeviceChart() {
      this.deviceColum = this.$echarts.init(this.$refs.device);
      this.$nextTick(() => {
        //智能社区安防：0dea2afb-2841-47b8-ae2d-c8f1c12cd74f 智能居家监护：75ec3569-ec92-4ab5-a72c-d602eee58e7e
        GetWorkOrderSumWithTypeList({
          parentID: "75ec3569-ec92-4ab5-a72c-d602eee58e7e",
        }).then((res) => {
          let countNums = 0;
          let data = [];
          if (res.result && res.result.length > 0) {
            data = res.result.map((item) => {
              countNums += Number(item.value);
              return {
                name: item.name,
                value: Number(item.value),
              };
            });
          }
          console.log(data);

          this.devicreOption.graphic[0].style.text = countNums;
          this.devicreOption.series[0].data = data;
          console.log(this.devicreOption);
          this.deviceColum.setOption(this.devicreOption);
        });
      });
    },
    //获取用户工单统计
    initServiceChart() {
      this.serviceColum = this.$echarts.init(this.$refs.service);
      this.$nextTick(() => {
        //智能社区安防：0dea2afb-2841-47b8-ae2d-c8f1c12cd74f 智能居家监护：75ec3569-ec92-4ab5-a72c-d602eee58e7e
        GetOrderSumWithTypeList({
          parentID: "75ec3569-ec92-4ab5-a72c-d602eee58e7e",
        }).then((res) => {
          let countNums = 0;
          let data = [];
          if (res.result && res.result.length > 0) {
            data = res.result.map((item) => {
              countNums += Number(item.value);
              return {
                name: item.name,
                value: Number(item.value),
              };
            });
          }
          this.serviceOption.graphic[0].style.text = countNums;
          this.serviceOption.series[0].data = data;
          this.serviceColum.setOption(this.serviceOption);
        });
      });
    },
    //获取统计折线图
    getLinde() {
      this.serviceLine = this.$echarts.init(this.$refs.serviceLine);
      GetWorkOrderSumMonthList({
        parentTypeID: "75ec3569-ec92-4ab5-a72c-d602eee58e7e",
        monthNum: this.monthNum,
      }).then((res) => {
        if (res.result.xAxis) {
          let xAxis = res.result.xAxis;
          this.serviceLineOption.xAxis.data = xAxis;
        }
        let datal = res.result.data1;
        //工单数
        this.serviceLineOption.series[0].data = datal;
        //

        GetOrderSumMonthList({
          parentTypeID: "75ec3569-ec92-4ab5-a72c-d602eee58e7e",
          monthNum: this.monthNum,
        }).then((res) => {
          this.serviceLineOption.series[1].data = res.result.data1;
          this.serviceLineOption.series[2].data = res.result.data2;
          this.serviceLine.setOption(this.serviceLineOption);
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
//

.dataBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dataInfo {
  width: 48%;
  background: #ffffff;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 8px 8px;
  height: 300px;
}
.serviceLine {
  width: 100%;
  height: 286px;
}
</style>
